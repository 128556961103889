
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "video": images({ label: "视频", type: "video" }),
            "cover": images({ label: "视频封面" }),

            "advantage": {
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                        "title": {
                            "type": "string",
                            "ui": {
                                "label": "title"
                            }
                        },
                        "thumb": images({}),
                    }
                },
                "ui": {
                    "label": "advantage"
                }
            },


        }
    }