

























































import Vue from "vue";
import formSchemaSource from "@/common/form/xiuduyou";
export default Vue.extend({
  data() {
    return {
      id: undefined,
      formSchemaSource,
      form: {},
      isPlaying: false,
    };
  },
  mounted() {
    this.getAllDatas();
    this.doAnimation();
  },

  methods: {
    getAllDatas() {},

    onPlay() {
      // @ts-ignore
      this.$refs.video.play();
    },
    doAnimation() {
      this.$ScrollReveal().reveal(".video_wrap", {
        reset: true,
        duration: 1000,
        distance: "150%",
        origin: "top",
        scale: 0.8,
      });
    },
  },
});
